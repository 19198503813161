<template>
	<div>
		<div class="card card-custom card-stretch gutter-b">
			<div class="card-header border-0">
				<h3 class="card-title font-weight-bolder">Current Calls</h3>
				<div class="col-5 d-flex align-items-center">
					<div style="width: 100%">
						<div class="row">
							<div class="col-3">
								<div class="search-box">
									<b-form-input class="search-bar" size="sm" v-model="ccAccountsearchText" placeholder="Account" debounce="500" />
									<b-icon-search class="search-icon" />
								</div>
							</div>
							<div class="col-3">
								<div class="search-box">
									<b-form-input class="search-bar" size="sm" v-model="ccCallingsearchText" placeholder="Calling" debounce="500" />
									<b-icon-search class="search-icon" />
								</div>
							</div>
							<div class="col-3">
								<div class="search-box">
									<b-form-input class="search-bar" size="sm" v-model="ccCalledsearchText" placeholder="Called" debounce="500" />
									<b-icon-search class="search-icon" />
								</div>
							</div>
							<div class="col-3">
								<span class="text-dark-75 font-weight-bold d-block font-size-lg pt-2"> {{ filteredCurrentCalls.length }} Calls </span>
							</div>
						</div>
					</div>
				</div>
			</div>
			<ITCSpinner :loaded="ccloaded">
				<div class="card-body pt-2">
					<b-table :fields="ccfields" :items="filteredCurrentCalls" :current-page="ccCurrentPage" :per-page="ccPerPage">
						<template #cell(SiteName)="data">
							<span class="text-dark-75 font-weight-bolder d-block font-size-lg">
								<router-link :to="{ name: 'site', params: { id: data.item.SiteId } }">
									{{ data.item.SiteName }}
								</router-link>
							</span>
						</template>
					</b-table>
					<div class="footer" v-if="filteredCurrentCalls.length > 0 && currentcalls.length > ccPerPage">
						<b-pagination class="pagination" v-model="ccCurrentPage" :total-rows="filteredCurrentCalls.length" :per-page="ccPerPage" />
					</div>
				</div>
			</ITCSpinner>
		</div>

		<div class="card card-custom card-stretch gutter-b">
			<div class="card-header border-0">
				<h3 class="card-title font-weight-bolder">Recent Calls</h3>
				<div class="col-5 d-flex align-items-center">
					<div style="width: 100%">
						<div class="row">
							<div class="col-3">
								<div class="search-box">
									<b-form-input class="search-bar" size="sm" v-model="cdrAccountsearchText" placeholder="Account" debounce="500" />
									<b-icon-search class="search-icon" />
								</div>
							</div>
							<div class="col-3">
								<div class="search-box">
									<b-form-input class="search-bar" size="sm" v-model="cdrCallingsearchText" placeholder="Calling" debounce="500" />
									<b-icon-search class="search-icon" />
								</div>
							</div>
							<div class="col-3">
								<div class="search-box">
									<b-form-input class="search-bar" size="sm" v-model="cdrCalledsearchText" placeholder="Called" debounce="500" />
									<b-icon-search class="search-icon" />
								</div>
							</div>
							<div class="col-3">
								<span class="text-dark-75 font-weight-bold d-block font-size-lg pt-2"> {{ filteredCDRs.length }} Calls </span>
							</div>
						</div>
					</div>
				</div>
			</div>
			<ITCSpinner :loaded="cdrloaded">
				<div class="card-body pt-2">
					<b-table :fields="cdrfields" :items="filteredCDRs" :current-page="cdrCurrentPage" :per-page="cdrPerPage">
						<template #cell(SiteName)="data">
							<span class="text-dark-75 font-weight-bolder d-block font-size-lg">
								<router-link :to="{ name: 'site', params: { id: data.item.SiteId } }">
									{{ data.item.SiteName }}
								</router-link>
							</span>
						</template>
					</b-table>
					<div class="footer" v-if="filteredCDRs.length > 0 && cdrs.length > cdrPerPage">
						<b-pagination class="pagination" v-model="cdrCurrentPage" :total-rows="filteredCDRs.length" :per-page="cdrPerPage" />
					</div>
				</div>
			</ITCSpinner>
		</div>

		<div class="card card-custom card-stretch gutter-b">
			<div class="card-header border-0">
				<h3 class="card-title font-weight-bolder">Registrations</h3>
				<div class="col-5 d-flex align-items-center">
					<div style="width: 100%">
						<div class="row">
							<div class="col-3">
								<div class="search-box">
									<b-form-input class="search-bar" size="sm" v-model="rAccountsearchText" placeholder="Account" debounce="500" />
									<b-icon-search class="search-icon" />
								</div>
							</div>
							<div class="col-3">
								<div class="search-box">
									<b-form-input class="search-bar" size="sm" v-model="rUsernamesearchText" placeholder="User Name" debounce="500" />
									<b-icon-search class="search-icon" />
								</div>
							</div>
							<div class="col-3">
								<div class="search-box">
									<b-form-input class="search-bar" size="sm" v-model="rAorsearchText" placeholder="AOR" debounce="500" />
									<b-icon-search class="search-icon" />
								</div>
							</div>
							<div class="col-3">
								<span class="text-dark-75 font-weight-bold d-block font-size-lg pt-2"> {{ filteredRegistrations.length }} Registrations </span>
							</div>
						</div>
					</div>
				</div>
			</div>
			<ITCSpinner :loaded="rloaded">
				<div class="card-body pt-2">
					<b-table :fields="rfields" :items="filteredRegistrations" :current-page="rCurrentPage" :per-page="rPerPage"> </b-table>
					<div class="footer" v-if="filteredRegistrations.length > 0 && registrations.length > rPerPage">
						<b-pagination class="pagination" v-model="rCurrentPage" :total-rows="filteredRegistrations.length" :per-page="rPerPage" />
					</div>
				</div>
			</ITCSpinner>
		</div>
	</div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module';
import ITCSpinner from '@/view/content/loaders/itcSpinner.vue';
export default {
	name: '',
	data() {
		return {
			currentcalls: [],
			ccloaded: false,
			ccAccountsearchText: null,
			ccCallingsearchText: null,
			ccCalledsearchText: null,
			ccCurrentPage: 1,
			ccPerPage: 8,
			ccfields: [
				{ key: 'DATE_TIME', label: 'Call Start Time', sortable: true },
				{ key: 'SiteName', label: 'Site' },
				{ key: 'ACCOUNT', label: 'Account' },
				{ key: 'ANI', label: 'Calling' },
				{ key: 'DNIS', label: 'Called' },
				{ key: 'duration', label: 'Duration' },
			],

			cdrs: [],
			cdrloaded: false,
			cdrAccountsearchText: null,
			cdrCallingsearchText: null,
			cdrCalledsearchText: null,
			cdrCurrentPage: 1,
			cdrPerPage: 8,
			cdrfields: [
				{ key: 'DATE_TIME', label: 'Call Start Time', sortable: true },
				{ key: 'SiteName', label: 'Site' },
				{ key: 'ACCOUNT', label: 'Account' },
				{ key: 'ANI', label: 'Calling' },
				{ key: 'DNIS', label: 'Called' },
				{ key: 'duration', label: 'Duration' },
			],

			registrations: [],
			rloaded: false,
			rAccountsearchText: null,
			rUsernamesearchText: null,
			rAorsearchText: null,
			rCurrentPage: 1,
			rPerPage: 10,
			rfields: [
				{ key: 'DID', label: 'SIP Account', sortable: true },
				{ key: 'account', label: 'User Name' },
				{ key: 'node', label: 'Server' },
				{ key: 'aor', label: 'AOR' },
				{ key: 'reg', label: 'Last Registration', sortable: true },
			],
		};
	},
	components: {
		ITCSpinner,
	},
	computed: {
		filteredCurrentCalls() {
			if (!this.ccAccountsearchText && !this.ccCallingsearchText && !this.ccCalledsearchText) return this.currentcalls;
			var f = this.currentcalls.filter(call => {
				return this.ccAccountsearchText ? call.ACCOUNT.toLowerCase().includes(this.ccAccountsearchText.toLowerCase()) : true;
			});
			f = f.filter(call => {
				return this.ccCallingsearchText ? (call.ANI + '').includes(this.ccCallingsearchText.toLowerCase()) : true;
			});
			f = f.filter(call => {
				return this.ccCalledsearchText ? (call.DNIS + '').includes(this.ccCalledsearchText.toLowerCase()) : true;
			});
			return f;
		},
		filteredCDRs() {
			if (!this.cdrAccountsearchText && !this.cdrCallingsearchText && !this.cdrCalledsearchText) return this.cdrs;

			var f = this.cdrs.filter(call => {
				return this.cdrAccountsearchText ? call.ACCOUNT.toLowerCase().includes(this.cdrAccountsearchText.toLowerCase()) : true;
			});
			f = f.filter(call => {
				return this.cdrCallingsearchText ? (call.ANI + '').includes(this.cdrCallingsearchText.toLowerCase()) : true;
			});

			f = f.filter(call => {
				return this.cdrCalledsearchText ? (call.DNIS + '').includes(this.cdrCalledsearchText.toLowerCase()) : true;
			});
			return f;
		},
		filteredRegistrations() {
			if (!this.rAccountsearchText && !this.rUsernamesearchText && !this.rAorsearchText) return this.registrations;
			var f = this.registrations.filter(reg => {
				return this.rAccountsearchText && reg.DID ? (reg.DID + '').toLowerCase().includes(this.rAccountsearchText.toLowerCase()) : true;
			});

			f = f.filter(reg => {
				return this.rUsernamesearchText && reg.account ? reg.account.toLowerCase().includes(this.rUsernamesearchText.toLowerCase()) : true;
			});
			f = f.filter(reg => {
				return this.rAorsearchText && reg.aor ? reg.aor.toLowerCase().includes(this.rAorsearchText.toLowerCase()) : true;
			});
			return f;
		},
	},
	mounted() {
		this.$store.dispatch(SET_BREADCRUMB, [{ title: 'VOIP' }]);
	},
	created() {
		this.ccloaded = false;
		this.$http.get('tsip/currentcalls/account').then(resp => {
			this.currentcalls = resp.data.data;
			this.ccloaded = true;
		});
		this.cdrloaded = false;
		this.$http.get('tsip/recentcalls/account/10/1000').then(resp => {
			this.cdrs = resp.data.data;
			this.cdrloaded = true;
		});

		this.ccloaded = false;
		this.$http.get('tsip/registrations/account').then(resp => {
			this.registrations = resp.data.data.DIDs;
			this.rloaded = true;
		});
	},
};
</script>

<style scoped>
.search-box {
	position: relative;
}
.search-bar {
	padding-left: 30px;
}
.search-icon {
	position: absolute;
	top: 10px;
	left: 10px;
}
.footer {
	padding: 15px 30px 0px;
}
.pagination {
	float: right;
}
</style>
